/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import { IElxData, IUpdateElxDataStatusRequest } from '../../types'
import { EditButton } from '../Buttons'
import { capitalize } from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { useGetModuleListQuery, useUpdateModuleStatusMutation } from '../../redux/api/moduleApi'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = typeof a[orderBy] === 'string' ? String(a[orderBy]).toLowerCase() : a[orderBy]
  const bValue = typeof b[orderBy] === 'string' ? String(b[orderBy]).toLowerCase() : b[orderBy]

  if (bValue < aValue) {
    return -1
  }
  if (bValue > aValue) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  id: string
  label: string
}

const getHeadCells = (labelForId: string) => {
  const headCells: HeadCell[] = [
    {
      id: 'id',
      label: labelForId,
    },
    {
      id: 'createdOn',
      label: 'Created',
    },
    {
      id: 'createdBy',
      label: 'Created By',
    },
    {
      id: 'updatedOn',
      label: 'Updated',
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
    },
  ]
  if (labelForId === 'Lead Type') {
    headCells.splice(1, 0, {
      id: 'description',
      label: 'Description',
    })
  }
  if (labelForId === 'Module') {
    headCells.splice(1, 0, {
      id: 'moduleDescription',
      label: 'Description',
    })
  }
  return headCells
}

interface ElxDataTableHeadProps {
  headLabelForId: string
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  editable?: boolean
  checkbox?: boolean
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  numSelected: number
  rowCount: number
}

function ElxDataTableHead(props: ElxDataTableHeadProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    editable = true,
    checkbox = true,
    onSelectAllClick,
    numSelected,
    rowCount,
  } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const headCells = getHeadCells(props.headLabelForId)

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        {checkbox && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all displayed rows' }}
            />
          </TableCell>
        )}

        {editable && (
          <TableCell align='center' padding='checkbox' sx={{ border: 'none', color: 'white' }}>
            Action
          </TableCell>
        )}

        <TableCell sx={{ border: 'none', color: 'white' }}>Status</TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface Props {
  headLabelForId: string
  rows: IElxData[]
  disableElxData: (data: IUpdateElxDataStatusRequest) => void
  editElxData: (data: IElxData) => void
  searchKey?: string
  perPage?: number
  gapPerPage?: number
  editable?: boolean
  checkbox?: boolean
  refetch?: () => void
}

export const ElxDataTable = ({
  headLabelForId = '',
  rows,
  searchKey = '',
  disableElxData,
  editElxData,
  perPage = 10,
  gapPerPage = 15,
  editable = true,
  checkbox = true,
  refetch,
}: Props) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('moduleName')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [open, setOpen] = React.useState(false)

  const [action, setAction] = React.useState<string>('')
  const [updateStatus, { isSuccess, isError, isLoading: loading, error }] =
    useUpdateModuleStatusMutation()

  const styles = {
    container: {
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    actionButton: {
      backgroundColor: '#1976d2',
      color: 'white',
      textTransform: 'none',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: '#115293',
      },
    },
    modalButton: {
      textTransform: 'none',
      textDecoration: 'none',
      backgroundColor: '#f1f1f1',
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
    },
  }

  // Conditionally set rowPerPageValue based on gapPerPage presence
  const rowPerPageValue = gapPerPage
    ? [perPage, perPage + gapPerPage, perPage + gapPerPage * 2] // If gapPerPage exists
    : [perPage, perPage * 2, perPage * 3] // If gapPerPage doesn't exist

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => (n.moduleId ? n.moduleId.toString() : n.id.toString()))
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleAction = (actionType: string) => {
    setAction(actionType)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setAction('')
  }

  const handleConfirm = async () => {
    const updatedData = {
      moduleIds: selected,
      isActiveFlg: action === 'activate',
    }
    updateStatus(updatedData)
    setOpen(false)
    refetch?.()
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      {checkbox && (
        <Box sx={styles.actionsContainer}>
          <Button
            variant='contained'
            sx={styles.actionButton}
            disabled={!selected.length}
            onClick={() => handleAction('activate')}
          >
            Activate
          </Button>
          <Button
            variant='contained'
            sx={styles.actionButton}
            disabled={!selected.length}
            onClick={() => handleAction('deactivate')}
          >
            Deactivate
          </Button>
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to {action} the selected statuses?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={styles.modalButton}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} sx={styles.modalButton}>
            Confirm
            {loading && <CircularProgress size={20} sx={{ ml: 1 }} />}
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='elxDataTable'>
            <ElxDataTableHead
              editable={editable}
              checkbox={checkbox}
              headLabelForId={headLabelForId}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              numSelected={selected.length}
              rowCount={rows.length}
            />
            <TableBody>
              {/* @ts-ignore */}
              {stableSort(rows, getComparator(order, orderBy))
                .filter((elxData) =>
                  String(elxData[orderBy])
                    .toLocaleLowerCase()
                    .includes(searchKey.toLocaleLowerCase()),
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                .map((row, index) => {
                  const isItemSelected =
                    selected.indexOf(
                      row.moduleId ? row.moduleId.toString() : row.id?.toString(),
                    ) !== -1
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      selected={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                    >
                      {checkbox && (
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleClick(
                              event,
                              row.moduleId ? row.moduleId.toString() : row.id.toString(),
                            )
                          }
                        />
                      )}

                      {editable && (
                        <TableCell>
                          <EditButton
                            //@ts-ignore
                            edit={() => editElxData(row)}
                            disable={() => {
                              disableElxData({
                                moduleIds: row.moduleId as number,
                                isActiveFlg: row.isActiveFlg ? 0 : 1,
                              })
                            }}
                            isActive={row.isActiveFlg}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        {row?.isActiveFlg ? (
                          <Chip label='Active' color='primary' size='small' />
                        ) : (
                          <Chip label='Inactive' color='error' size='small' />
                        )}
                      </TableCell>
                      <TableCell>
                        {capitalize((row?.data as string) || (row?.moduleName as string))}
                      </TableCell>

                      {headLabelForId === 'Lead Type' && <TableCell>{row?.description}</TableCell>}
                      {headLabelForId === 'Module' && (
                        <TableCell>{row?.moduleDescription}</TableCell>
                      )}
                      <TableCell>{moment(row?.createdOn).format('L')}</TableCell>
                      <TableCell>{row?.createdBy ? row?.createdBy : ''}</TableCell>
                      <TableCell>
                        {row?.updatedOn ? moment(row.updatedOn).format('L') : ''}
                      </TableCell>
                      <TableCell>{row?.updatedBy ? row?.updatedBy : ''}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowPerPageValue}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
