import React from 'react'
import ReactDOM from 'react-dom' // Updated import for React 17
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from '@mui/material'
import AuthMiddleware from './helpers/AuthMiddleware'
import { theme } from './theme'
import { App } from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

// Fontsource can be configured to load specific subsets, weights, and styles.
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Use ReactDOM.render instead of createRoot()
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <AuthMiddleware>
              <App />
            </AuthMiddleware>
          </CookiesProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals()
