/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { isEmpty } from 'lodash'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '500px',
  borderRadius: '12px',
}

const MapContainer = (props) => {
  const location = !isEmpty(props.locations) ? props.locations[0] : {}

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={16}
        center={{ lat: location.geo_lat, lng: location.geo_lon }}
      >
        {!isEmpty(props.locations) &&
          props.locations.map((location, index) => (
            <Marker
              key={location.global_id}
              position={{ lat: location.geo_lat, lng: location.geo_lon }}
              onClick={() => props.setActiveIndex(index)}
            />
          ))}
      </GoogleMap>
    </LoadScript>
  )
}

export default MapContainer
