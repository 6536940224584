import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Button, Container, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { IDealerGroups, IUpdateDealerGroupStatusRequest } from '../../types'
import { useGetApplicationListQuery } from '../../redux/api/applicationApi'
import { DealerGroupForm } from './DealerGroupForm'
import {
  useGetDealerGroupListQuery,
  useUpdateDealerGroupStatusMutation,
} from '../../redux/api/dealerGroupApi'
import { FullScreenLoader, DealerGroupTable } from '../../components'
import { useGetDealerGroupNumberDataQuery } from '../../redux/api/dealergroupnumberApi'
import axios from 'axios'

interface DealerGroup {
  dealerGroupNumberToLogoId: number
  application: string | null
  dealerGroupName: string | null
  dealerGroupNumber: string | null
  dealerLogoPath: string
  createdOn: string
  createdBy: string
  updatedOn: string
  updatedBy: string | null
  isActiveFlg: number
}

interface DealerListType {
  dealerGroupName: string
  dealerGroupNumber: string
}

export function DealerGroupPage() {
  const { isLoading, data, refetch } = useGetApplicationListQuery(null)
  // const {
  //   isLoading: isDealerGroupsLoading,
  //   isError: isDealerGroupsError,
  //   error: dealerGroupsError,
  //   data: dealerGroupNumbersListOptions,
  //   refetch: refetchDealer,
  // } = useGetDealerGroupNumberDataQuery()

  const {
    isLoading: load,
    data: dealerData,
    refetch: refetchDealerGroups,
  } = useGetDealerGroupListQuery(null)
  const [dealerList, setDealerList] = useState<DealerListType[]>([])
  const [updateStatus, { isSuccess, isError, isLoading: loading, error }] =
    useUpdateDealerGroupStatusMutation()
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [resetForm, setResetForm] = useState<string>('create')
  const [dealerGroupNumbersList, setDealerGroupNumbersList] = useState<DealerListType[]>([])

  // useEffect(() => {
  //   setDealerGroupNumbersList(dealerGroupNumbersListOptions)
  // }, [dealerGroupNumbersListOptions])

  useEffect(() => {
    const fetchDealerGroupList = async () => {
      try {
        const token = localStorage.getItem('access_token')
        const baseUrl = process.env.REACT_APP_CONTRACT_SERVICE_API_ENDPOINT
        const endpoint = '/dealergrouplist'

        const response = await axios.get(`${baseUrl}${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setDealerGroupNumbersList(response.data)
      } catch (error) {
        console.error('Error fetching dealer group list:', error)
      }
    }

    fetchDealerGroupList()
  }, [])

  useEffect(() => {
    if (
      Array.isArray(dealerData) &&
      Array.isArray(dealerGroupNumbersList) &&
      dealerGroupNumbersList.length > 1 &&
      dealerData.length > 1
    ) {
      const copydealer = dealerData ? [...dealerData] : []
      const filteredArray = dealerGroupNumbersList.filter((item1) => {
        return !copydealer.some((item2) => item1.dealerGroupNumber === item2.dealerGroupNumber)
      })
      setDealerList(filteredArray)
    }
  }, [dealerGroupNumbersList, dealerData])

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      refetchDealerGroups()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [loading])
  const [isVisible, setIsVisible] = React.useState(false)
  const [editableDealerGroup, setEditableDealerGroup] = React.useState<IDealerGroups | undefined>()

  if (isLoading || !data || loading) {
    return <FullScreenLoader />
  }
  const handleEditable = (data: IDealerGroups) => {
    const newDealer: DealerListType = {
      dealerGroupName: data.dealerGroupName ?? '',
      dealerGroupNumber: data.dealerGroupNumber ?? '',
    }

    // Push the new object into your DealerListType array
    setDealerList((prevList) => [...prevList, newDealer])
    setResetForm('update')
    setEditableDealerGroup(data)
    setIsVisible(true)
  }

  const handleStatusUpdate = (data: IUpdateDealerGroupStatusRequest) => {
    updateStatus(data)
    refetchDealerGroups()
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Dealer Group Logos
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px', textDecoration: 'none' }}
            startIcon={<AddIcon />}
            onClick={() => {
              setIsVisible(true)
              setEditableDealerGroup(undefined)
              setResetForm('create')
            }}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <DealerGroupTable
        rows={dealerData ?? []}
        searchKey={searchKey}
        editApplication={(data) => handleEditable(data)}
        disableApplication={handleStatusUpdate}
      />
      <DealerGroupForm
        open={isVisible}
        resetForm={resetForm}
        onClose={() => setIsVisible(false)}
        editableDealerGroup={editableDealerGroup}
        refetchDealerGroups={refetchDealerGroups}
        dealerGroupNumbersList={dealerList}
        setDealerList={setDealerList}
        dealerList={dealerList}
      />
    </Container>
  )
}
